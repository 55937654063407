import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import Gallery from '../../../gatsby-image-gallery/src'

const IndexPage = ({ data }) => {
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: node.childImageSharp.thumb.originalName,
  }))

  // Override some of Lightbox options to localise labels in French
  const lightboxOptions = {
    imageLoadErrorMessage: 'Chyba při načítání fotografie.',
    nextLabel: 'Další foto',
    prevLabel: 'Předchozí foto',
    zoomInLabel: 'Přiblížit',
    zoomOutLabel: 'Oddálit',
    closeLabel: 'Zavřít',
  }
  return (
    <Layout>
      <Gallery images={images} lightboxOptions={lightboxOptions} />
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
